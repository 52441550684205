<template>
  <v-app>
    <dashboard-core-app-bar />

    <dashboard-core-drawer />

    <dashboard-core-view />

    <!-- <dashboard-core-settings /> -->
  </v-app>
</template>

<script>
import { defineComponent, ref } from "@vue/composition-api";
export default defineComponent({
  name: "DashboardIndex",

  components: {
    DashboardCoreAppBar: () => import("../App/components/core/AppBar"),
    DashboardCoreDrawer: () => import("../App/components/core/Drawer"),
    // DashboardCoreSettings: () => import('../App/components/core/Settings'),
    DashboardCoreView: () => import("../App/components/core/View")
  },
  setup() {
    const expandOnHover = ref(false);

    return { expandOnHover };
  }
});
</script>